import axios from '..';
import type ResType from '../type';
import type {
  DeleteReportCardDetailReqType,
  DeleteReportCardReqType,
  DeleteStandarizedSubjectReqType,
  GetTestReqType,
  PatchReportCardDetailReqType,
  PatchReportCardReqType,
  PatchStandarizedSubjectReqType,
  PostReportCardDetailReqType,
  PostReportCardReqType,
  PostStandarizedSubjectReqType,
  PostTestReqType,
} from './req';
import type {
  ActivityAwardTestResType,
  DeleteStandarizedSubjectResType,
  GetReportCardSubjectsResType,
  GetTestWithSubjectIdResType,
  HighSchoolTestResType,
  LangaugeTestResType,
  PatchStandarizedSubjectResType,
  PostReportCardResType,
  PostStandarizedSubjectResType,
} from './res';

export type Tests = {
  'report-cards': HighSchoolTestResType;
  'language-tests': LangaugeTestResType;
  'standarized-tests': StandardizedTestWithExamInfo;
  'activities-awards': ActivityAwardTestResType;
};

export type ExpandedTests = {
  IB: IB;
  'A-LEVEL': ALEVEL;
  AP: AP;
};

export const getTestSubjectByUserId = <
  TtestType extends keyof Tests,
  Ttest extends keyof Tests[TtestType],
>(
  testType: TtestType,
  test: Ttest & string,
  { userId }: GetTestReqType,
) =>
  axios
    .get<ResType<Tests[TtestType][Ttest]>>(`/${testType}/${test}/users/${userId}`)
    .then((res) => res.data.data);

export const postTest = <TtestType extends keyof PostTestReqType>(
  testType: TtestType,
  body: PostTestReqType[TtestType],
) => axios.post<ResType<string>>(`/${testType}`, body).then((res) => res.data);

export const getTestSubjectIds = <Ttest extends keyof ExpandedTests>(test: Ttest) =>
  axios
    .get<GetTestWithSubjectIdResType<keyof ExpandedTests[Ttest]>>(
      `/standarized-tests/${test}/subjects`,
    )
    .then((res) => res.data);

export const getReportCardSubjects = () =>
  axios.get<GetReportCardSubjectsResType>('/report-card-subjects').then((res) => res.data);

export const getTestByUserId = <TtestType extends keyof Tests>(
  testType: TtestType,
  { userId }: GetTestReqType,
) => axios.get<ResType<Tests[TtestType]>>(`/${testType}/users/${userId}`).then((res) => res.data);

export const deleteTestById = <TtestType extends keyof Tests>(testType: TtestType, id: string) =>
  axios.delete<ResType<null>>(`/${testType}/${id}`).then((res) => res.data);

export const postReportCardSubjects = (name: string) =>
  axios
    .post<
      ResType<{
        reportCardSubjectId: string;
      }>
    >('/report-card-subjects', { name })
    .then((res) => res.data.data);

export const deleteUserStandardizedTestSubject = (test: keyof ExpandedTests, subjectId: string) =>
  axios.delete<ResType<null>>(`/standarized-tests/${test}/${subjectId}`).then((res) => res.data);

export const deleteUserReportCardDetails = (id: string) =>
  axios.delete<ResType<null>>(`/report-cards/details/${id}`).then((res) => res.data);

export const uploadReportCardTranscript = ({
  userId,
  transacript,
}: {
  userId: string;
  transacript: string;
}) => {
  return axios
    .post<ResType<string>>(`/report-cards/transcript`, { userId, transacript })
    .then((res) => res.data);
};

export const reportCards = {
  post: (body: PostReportCardReqType) =>
    axios.post<ResType<PostReportCardResType>>('/report-cards', body),
  patch: ({ reportCardId, ...body }: PatchReportCardReqType) =>
    axios.patch<ResType<PatchReportCardReqType>>(`/report-cards/${reportCardId}`, body),
  delete: ({ reportCardId }: DeleteReportCardReqType) =>
    axios.delete<ResType<DeleteReportCardReqType>>(`/report-cards/${reportCardId}`),
};

export const reportCardDetail = {
  post: ({ reportCardId, ...body }: PostReportCardDetailReqType) =>
    axios.post<ResType<PostReportCardDetailReqType>>(`/report-cards/${reportCardId}`, body),
  patch: ({ reportCardDetailId, ...body }: PatchReportCardDetailReqType) =>
    axios.patch<ResType<string>>(`/report-cards/details/${reportCardDetailId}`, body),
  delete: ({ reportCardDetailId }: DeleteReportCardDetailReqType) =>
    axios.delete<ResType<string>>(`/report-cards/details/${reportCardDetailId}`),
};

export const standarizedSubjects = {
  get: <Ttest extends keyof ExpandedTests>(test: Ttest) =>
    axios
      .get<GetTestWithSubjectIdResType<keyof ExpandedTests[Ttest]>>(
        `/standarized-tests/${test}/subjects`,
      )
      .then((res) => res.data),
  post: ({ subject, ...body }: PostStandarizedSubjectReqType<keyof ExpandedTests>) =>
    axios
      .post<PostStandarizedSubjectResType>(`/standarized-tests/${subject}/subjects`, body)
      .then((res) => res.data),
  patch: ({ subject, subjectId, ...body }: PatchStandarizedSubjectReqType<keyof ExpandedTests>) =>
    axios
      .patch<PatchStandarizedSubjectResType>(
        `/standarized-tests/${subject}/subjects/${subjectId}`,
        body,
      )
      .then((res) => res.data),
  delete: ({ subject, subjectId }: DeleteStandarizedSubjectReqType<keyof ExpandedTests>) =>
    axios
      .delete<DeleteStandarizedSubjectResType>(
        `/standarized-tests/${subject}/subjects/${subjectId}`,
      )
      .then((res) => res.data),
};
