import axios from '..';
import type {
  DeleteMockUserAdmissionsReqType,
  GetBoardsBoardIdPostsReqType,
  PatchMockUserAdmissionsReqType,
  PostAdmissionNewsReqType,
  PostMockUserAdmissionsReqType,
} from './req';
import type {
  DeleteMockUserAdmissionsResType,
  GetBoardsBoardIdPostsResType,
  GetMockUserAdmissionsResType,
  PatchMockUserAdmissionsResType,
  PostAdmissionNewsResType,
  PostMockUserAdmissionsResType,
} from './res';

export const postMockUserAdmissions = (body: PostMockUserAdmissionsReqType) => {
  return axios
    .post<PostMockUserAdmissionsResType>('/admins/mock-user-admissions', body)
    .then((res) => res.data);
};

export const getMockUserAdmissions = () => {
  return axios
    .get<GetMockUserAdmissionsResType>('/admins/mock-user-admissions')
    .then((res) => res.data);
};

export const mockUserAdmissions = {
  get: () =>
    axios.get<GetMockUserAdmissionsResType>('/admins/mock-user-admissions').then((res) => res.data),
  post: (body: PostMockUserAdmissionsReqType) =>
    axios
      .post<PostMockUserAdmissionsResType>('/admins/mock-user-admissions', body)
      .then((res) => res.data),
  patch: ({ userAdmissionId, ...body }: PatchMockUserAdmissionsReqType) =>
    axios
      .patch<PatchMockUserAdmissionsResType>(
        `/admins/mock-user-admissions/${userAdmissionId}`,
        body,
      )
      .then((res) => res.data),
  delete: ({ userAdmissionId }: DeleteMockUserAdmissionsReqType) =>
    axios
      .delete<DeleteMockUserAdmissionsResType>(`/admins/mock-user-admissions/${userAdmissionId}`)
      .then((res) => res.data),
};

export const createAllAdmissionsBySimulatedApplicationId = (simulatedApplicationId: number) => {
  return axios
    .post(`/admins/admissions`, {
      simulatedApplicationId,
    })
    .then((res) => res.data);
};

export const createAllCompetitiveUnitsBySimulatedApplicationId = (
  simulatedApplicationId: number,
) => {
  return axios
    .post(`/admins/competitive-units`, {
      simulatedApplicationId,
    })
    .then((res) => res.data);
};

export const getBoardsByBoardIds = async ({
  boardIds,
  ...params
}: GetBoardsBoardIdPostsReqType) => {
  const queryParamString = boardIds.map((n) => `boardIds[]=${n}`).join('&');
  return (
    await axios.get<GetBoardsBoardIdPostsResType>(`/admins/boards?${queryParamString}`, { params })
  ).data;
};

export const postAdmissionNews = async (body: PostAdmissionNewsReqType) => {
  return (await axios.post<PostAdmissionNewsResType>(`/admins/admission-news`, body)).data;
};
