import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
import { useCountries } from '@hook/useCountries';
import { useHighschools } from '@hook/useHighschools';
import { useUserInfo } from '@hook/useUserInfo';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import { putUserInfoByAdmin } from '@simppl/repository/user';
import { AtSign } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
const WRITING_MODE = '직접입력';
const notWritingEmailDomains = {
    'gmail.com': 'gmail.com',
    'naver.com': 'naver.com',
    'icloud.com': 'icloud.com',
    'hotmail.com': 'hotmail.com',
};
const emailDomainList = Object.values(notWritingEmailDomains);
const emailDomains = [
    {
        label: WRITING_MODE,
        value: WRITING_MODE,
    },
    ...Object.entries(notWritingEmailDomains).map(([key, value]) => ({ label: key, value })),
];
export function EditUserInfoModal({ isOpen, onOpenChange, userId }) {
    const userInfo = useUserInfo({ userId });
    const [selectedDomain, setSelectedDomain] = useState('');
    const [highSchool, setHighSchool] = useState(userInfo.highSchool);
    const [country, setCountry] = useState(userInfo.country);
    const { countryNameIdMap, countryNames } = useCountries({ isApprove: true });
    const countryId = countryNameIdMap[country];
    const methods = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
    });
    const { control, handleSubmit } = methods;
    const { highschoolNames } = useHighschools({
        countryId,
        isApprove: true,
    });
    const [emailId, name, emailDomain, admissionCount] = [
        'emailId',
        'name',
        'emailDomain',
        'admissionCount',
    ].map((t) => methods.watch(t));
    const setEmailDomain = (value) => methods.setValue('emailDomain', value);
    const handleSelectedDomain = (e) => {
        const { value } = e.target;
        setEmailDomain(value === WRITING_MODE ? '' : value);
        setSelectedDomain(value);
    };
    const email = !!emailId && !!emailDomain ? `${emailId}@${emailDomain}` : '';
    const isWritingMode = selectedDomain === WRITING_MODE;
    useEffect(() => {
        methods.setValue('name', userInfo.name);
        methods.setValue('nickname', userInfo.nickname);
        const [userEmail, userEmailDomain] = userInfo.email.split('@');
        setSelectedDomain(emailDomainList.includes(userEmailDomain) ? userEmailDomain : WRITING_MODE);
        methods.setValue('emailId', userEmail);
        methods.setValue('emailDomain', userEmailDomain);
    }, []);
    const putUserInfoHook = useMutation((body) => putUserInfoByAdmin(body));
    return (_jsx(Modal, { isOpen: isOpen, onOpenChange: onOpenChange, children: _jsx(ModalContent, { children: (onClose) => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: "\uAE30\uBCF8 \uC815\uBCF4 \uC218\uC815" }), _jsx(ModalBody, { children: _jsx(FormProvider, { ...methods, children: _jsxs("form", { id: 'authorization', className: 'flex flex-col gap-4', onSubmit: (e) => {
                                    e.preventDefault();
                                    putUserInfoHook.mutate({
                                        name,
                                        userId: userInfo.id,
                                        status: userInfo.status,
                                        email: `${emailId}@${emailDomain}`,
                                        nickname: userInfo.nickname,
                                    }, {
                                        onSuccess: () => onClose(),
                                        onError: (error) => alert(error.response.data.message),
                                    });
                                }, children: [_jsx(Controller, { name: 'name', control: control, defaultValue: '', render: ({ field }) => (_jsx(Input, { labelPlacement: 'outside', placeholder: '\uC774\uB984', ...field })) }), _jsx(Controller, { name: 'nickname', control: control, defaultValue: '', render: ({ field }) => (_jsx(Input, { labelPlacement: 'outside', placeholder: '\uB2C9\uB124\uC784', ...field })) }), _jsxs("div", { className: 'flex w-full items-start justify-between gap-2', children: [_jsx(Controller, { name: 'emailId', defaultValue: '', control: control, render: ({ field }) => (_jsx(Input, { labelPlacement: 'outside', className: '!w-[154px]', placeholder: '\uC774\uBA54\uC77C\uC8FC\uC18C', ...field })) }), _jsx(AtSign, { className: 'h-[40px] w-[16px]' }), _jsx(Controller, { name: 'emailDomain', defaultValue: '', control: control, render: ({ field }) => (_jsx(Input, { labelPlacement: 'outside', className: 'w-[130px]', ...field, disabled: !isWritingMode, onChange: (e) => setEmailDomain(e.target.value) })) }), _jsx(Select, { className: 'h-[40px] max-w-[140px]', labelPlacement: 'outside', items: emailDomains, selectedKeys: [selectedDomain], onChange: handleSelectedDomain, children: (animal) => _jsx(SelectItem, { children: animal.label }, animal.value) })] })] }) }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { type: 'button', color: 'danger', variant: 'light', onPress: onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => { }, children: "\uC800\uC7A5" })] })] })) }) }));
}
