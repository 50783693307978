import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { HighSchoolSubjectModal } from '@component/subject/HighSchoolSubjectModal';
import { useReportCardSubjects } from '@hook/useReportCardSubjects';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { reportCardSubjects } from '@simppl/repository/report-card-subjects';
import { Search } from 'lucide-react';
import { useState } from 'react';
const columns = [
    {
        key: 'name',
        label: '이름',
    },
    {
        key: 'subject',
        label: '승인여부',
    },
    {
        key: 'settings',
        label: '비고',
    },
];
export function HighSchoolSubjectPage() {
    const data = useReportCardSubjects();
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (subject, value) => subject.name.includes(value));
    const [modalState, setModalState] = useState(null);
    const deleteSubjectHook = useMutation(reportCardSubjects.delete);
    const queryClient = useQueryClient();
    const { isOpen, onClose, onOpen, onOpenChange } = useDisclosure();
    return (_jsxs("div", { className: 'flex min-h-[80%] flex-col', children: [isOpen && modalState !== null && (_jsx(HighSchoolSubjectModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                    onClose();
                    setModalState(null);
                } })), _jsxs(Card, { className: 'grow', children: [_jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uD45C\uC900\uD654 \uACFC\uBAA9 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsxs(CardBody, { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row gap-4', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center gap-4', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                                setModalState({ mode: 'add' });
                                                onOpen();
                                            }, children: "\uACFC\uBAA9 \uCD94\uAC00" })] }) }), _jsxs(Table, { children: [_jsx(TableHeader, { columns: columns, children: (column) => _jsx(TableColumn, { children: column.label }, column.key) }), _jsx(TableBody, { items: items, children: (subject) => (_jsxs(TableRow, { className: 'relative', children: [_jsx(TableCell, { children: subject.name }), _jsx(TableCell, { children: subject.isApprove ? '승인' : '반려' }), _jsx(TableCell, { className: 'text-center', children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                            setModalState({
                                                                                mode: 'edit',
                                                                                subjectId: subject.id,
                                                                            });
                                                                            onOpen();
                                                                        }, children: "\uD3B8\uC9D1" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                            // eslint-disable-next-line no-restricted-globals
                                                                            if (!confirm('정말 삭제하시겠습니까?'))
                                                                                return;
                                                                            deleteSubjectHook.mutate({
                                                                                reportCardSubjectId: subject.id,
                                                                            }, {
                                                                                onSuccess: () => {
                                                                                    queryClient.invalidateQueries(['standarizedSubjects']);
                                                                                },
                                                                            });
                                                                        }, children: "\uC0AD\uC81C" })] })] }) })] }, subject.id)) })] })] }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] })] }));
}
