import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { UploadFile } from '@component/Uploader';
import { useUniversities } from '@hook/useUniversities';
import { Button, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { universities } from '@simppl/repository/universities';
import { useEffect, useState } from 'react';
export function UniversityModal(props) {
    const [input, setInput] = useState('');
    const [image, setImage] = useState('');
    const [isMain, setIsMain] = useState(false);
    // const [selectedExam, setSelectedExam] = useState<'exam' | 'document'>('exam');
    const university = props.mode === 'edit'
        ? useUniversities({
            name: '',
            // isMain null 전체 대학 조회
            page: 1,
            size: 1000000,
        }).data?.find((university) => university.universityId === props.universityId)
        : null;
    const queryClient = useQueryClient();
    const postUniversitiesHook = useMutation(universities.post);
    const patchUniversitiesHook = useMutation(universities.patch);
    useEffect(() => {
        if (props.mode === 'edit' && university) {
            setInput(university.name);
            setImage(university.image);
            setIsMain(university.isMain);
        }
    }, [props, university]);
    return (_jsx(Modal, { isOpen: props.isOpen, onOpenChange: props.onOpenChange, children: _jsx(ModalContent, { children: () => (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { className: 'flex flex-col gap-1', children: props.mode === 'add' ? '대학 추가' : '대학 수정' }), _jsxs(ModalBody, { children: [_jsx(Input, { value: input, onChange: (e) => setInput(e.target.value), label: '\uB300\uD559 \uC774\uB984', labelPlacement: 'outside' }), _jsx(UploadFile, { name: image, accept: '', testType: 'university', testName: input, setName: setImage }), _jsx(Checkbox, { isSelected: isMain, onValueChange: setIsMain, children: "\uC8FC\uC694 \uB300\uD559 \uC5EC\uBD80" })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { color: 'danger', variant: 'light', onPress: props.onClose, children: "\uCDE8\uC18C" }), _jsx(Button, { type: 'submit', form: 'authorization', color: 'primary', onPress: () => {
                                    if (props.mode === 'add')
                                        postUniversitiesHook.mutate({
                                            name: input,
                                            image,
                                            isMain,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['universities']);
                                                props.onClose();
                                            },
                                        });
                                    else if (props.mode === 'edit' && university?.universityId)
                                        patchUniversitiesHook.mutate({
                                            universityId: university.universityId,
                                            name: input,
                                            image,
                                            isMain,
                                        }, {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries(['universities']);
                                                props.onClose();
                                            },
                                        });
                                }, children: "\uC800\uC7A5" })] })] })) }) }));
}
