import getImgUrl from '@simppl/util/getImgUrl';

type CheckBoxProps = {
  checked: boolean;
  disabled?: boolean;
};

export default function CheckBox({ checked, disabled }: CheckBoxProps) {
  return disabled ? (
    <div className='bg-Base-Gray h-[24px] w-[24px] rounded-[4px]' />
  ) : (
    <img
      src={getImgUrl(`/asset/Checkbox/${checked ? 'Checked' : 'UnChecked'}.png`)}
      alt='체크 박스'
    />
  );
}
