/* eslint-disable no-nested-ternary */
import { Listbox } from '@headlessui/react';
import c from '@simppl/util/c';
import { ChevronDown, ChevronUp } from 'lucide-react';

import Text from './Text';

type SelectBoxProps = {
  className?: string;
  selectedColor?: `text-${string}`;
  textColor?: `text-${string}`;
  disables?: string[];
  currentValue: string;
  onClick: (value: string) => void;
  options: Record<string, string>;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
};

export default function SelectBox({
  className,
  selectedColor = 'text-Base-Deep-Gray',
  textColor,
  options = {},
  currentValue,
  onClick,
  disables = [],
  disabled,
  placeholder,
}: SelectBoxProps) {
  const isDisable = (value: string) => disables.some((v) => v === value);

  return (
    <div
      className={
        className
          ? `relative flex h-[40px] min-w-[124px] items-center rounded-[4px] ${className}`
          : 'relative flex h-[40px] min-w-[124px] items-center rounded-[4px] border-[1px] border-[#A6A6A6]'
      }
    >
      <Listbox
        as='ul'
        className='h-full w-full'
        value={currentValue}
        onChange={onClick}
        disabled={disabled}
      >
        {({ open }: { open: boolean }) => (
          <>
            <span className='absolute right-[16px] top-[50%] z-[-1] translate-y-[-50%] text-[#1F64DC]'>
              {open ? <ChevronUp /> : <ChevronDown />}
            </span>
            <Listbox.Button
              type='button'
              className={c(
                'h-full w-full rounded-[4px] pl-[22px] text-start outline-none',
                textColor || 'text-Blue-2',
                disabled ? 'bg-gray-100' : '',
              )}
            >
              {options?.[currentValue] ?? placeholder ?? ''}
            </Listbox.Button>
            <Listbox.Options className='absolute left-0 top-[40px] z-[999] flex w-full cursor-pointer flex-col gap-[8px] rounded-[4px] border-[0.5px] border-[#D6E0FD] bg-white py-[8px] pl-[16px]'>
              {Object.entries(options).map(([key, option]) => (
                <Listbox.Option
                  as='li'
                  key={option}
                  value={key}
                  disabled={disables.includes(option)}
                >
                  <Text
                    className='cursor-pointer'
                    color={
                      currentValue === option
                        ? selectedColor
                        : isDisable(option)
                          ? 'text-Base-Deep-Gray'
                          : 'text-Blue-5'
                    }
                    weight='Regular'
                    as='Label'
                    size={1}
                  >
                    {option}
                  </Text>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div >
  );
}
