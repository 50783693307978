import { setSentryContextUserInfoClear } from '@simppl/react-sentry';

import axios from '..';
import LoginStorage from './LoginStorage';
import type {
  DeleteUserReqType,
  GetAdmissionsAvailableCountsReqType,
  GetUserByIdReqType,
  GetUserInfoByIdReqType,
  GetUsersIdListReqType,
  GetUsersUserIdCommunityCommentsReqType,
  GetUsersUserIdCommunityPostsReqType,
  GetUsersValidateUserReqType,
  PostUsersCheckAuthCodeReqType,
  PostUsersDuplicateEmailReqType,
  PostUsersDuplicateNicknameReqType,
  PostUsersLoginReqType,
  PostUsersReqType,
  PostUsersSendEmailReqType,
  PutUserInfoByAdminReqType,
  PutUserInfoReqType,
  PutUsersPasswordReqType,
} from './req';
import type {
  DeleteUserResType,
  GetAdmissionsAvailableCountsResType,
  GetUserInfoResType,
  GetUserResType,
  GetUsersIdListResType,
  GetUsersPaymentHistoryResType,
  GetUsersResType,
  GetUsersUserIdCommunityCommentsResType,
  GetUsersUserIdCommunityPostsResType,
  GetUsersValidateUserResType,
  PostUsersCheckAuthCodeResType,
  PostUsersDuplicateEmailResType,
  PostUsersDuplicateNicknameResType,
  PostUsersIssueTokenResType,
  PostUsersLoginResType,
  PostUsersLogoutResType,
  PostUsersResType,
  PostUsersSendEmailResType,
  PutUserInfoByAdminResType,
  PutUserInfoResType,
  PutUsersPasswordResType,
} from './res';

export { userAdmissions, userAdmissionsId, userInterestAdmissions } from './admissions';

export const postUsersDuplicateEmail = (body: PostUsersDuplicateEmailReqType) =>
  axios
    .post<PostUsersDuplicateEmailResType>(`/users/duplicate-email`, body)
    .then((res) => res.data);

export const postUsersSendEmail = (body: PostUsersSendEmailReqType) =>
  axios.post<PostUsersSendEmailResType>(`/users/send-email`, body).then((res) => res.data);

export const postUsersCheckAuthCode = (body?: PostUsersCheckAuthCodeReqType) =>
  axios.post<PostUsersCheckAuthCodeResType>('/users/check-auth-code', body);

export const postUsers = (body: PostUsersReqType) =>
  axios.post<PostUsersResType>('/users', body).then((res) => res.data);

export const DeleteUser = ({ userId }: DeleteUserReqType) =>
  axios.delete<DeleteUserResType>(`/users/${userId}`).then((res) => res.data);

export const getUserInfo = () =>
  axios.get<GetUserInfoResType>(`/users/token-user-info`).then((res) => res.data);

export const getUserInfoById = ({ userId }: GetUserInfoByIdReqType) =>
  axios.get<GetUserInfoResType>(`/users/user-info/${userId}`).then((res) => res.data);

export const getUserById = ({ userId }: GetUserByIdReqType) =>
  axios.get<GetUserResType>(`/users/${userId}`).then((res) => res.data);

export const putUserInfoByAdmin = (body: PutUserInfoByAdminReqType) =>
  axios.put<PutUserInfoByAdminResType>('/users/user-info', body).then((res) => res.data);

export const putUserInfo = (body: PutUserInfoReqType) =>
  axios.put<PutUserInfoResType>('/users/info', body).then((res) => res.data);

export const getUsers = () => axios.get<GetUsersResType>('/users').then((res) => res.data);

export const postUsersLogin = ({ email, password, isAdmin = false }: PostUsersLoginReqType) =>
  axios.post<PostUsersLoginResType>('/users/login', { email, password, isAdmin }).then((res) => {
    const { token, refreshToken } = res.data.data;
    LoginStorage.setToken({ value: token, type: 'access' });
    LoginStorage.setToken({ value: refreshToken, type: 'refresh' });
    return res;
  });

export const postUsersIssueToken = () =>
  axios
    .post<PostUsersIssueTokenResType>('/users/issue-token', {
      refreshToken: LoginStorage.getToken({ type: 'refresh' }),
    })
    .then((res) => {
      const { token, refreshToken } = res.data.data;
      LoginStorage.setToken({ value: token, type: 'access' });
      LoginStorage.setToken({ value: refreshToken, type: 'refresh' });
      setSentryContextUserInfoClear();
      return res;
    });

export const postUsersLogout = () =>
  axios
    .post<PostUsersLogoutResType>('/users/logout')
    .catch(() => {
      return null;
    })
    .finally(() => {
      LoginStorage.removeToken({ type: 'access' });
      LoginStorage.removeToken({ type: 'refresh' });
      LoginStorage.setAutoLoginState(false);
      localStorage.removeItem('simppl-select-university-id');
      window.location.reload();
    });

export const getUsersPaymentHistory = () =>
  axios.get<GetUsersPaymentHistoryResType>('/users/payment-history').then((res) => res.data);

export const getUsersIdList = (params: GetUsersIdListReqType) =>
  axios.get<GetUsersIdListResType>(`/users/id-list`, { params }).then((res) => res.data);

export const putUsersPassword = (body: PutUsersPasswordReqType) =>
  axios.put<PutUsersPasswordResType>('/users/password', body);

export const getUsersValidateUser = (params: GetUsersValidateUserReqType) =>
  axios.get<GetUsersValidateUserResType>('/users/validate-user', { params });

export const postUsersDuplicateNickname = (body: PostUsersDuplicateNicknameReqType) =>
  axios.post<PostUsersDuplicateNicknameResType>('users/duplicate-nickname', body);

export const getAdmissionsAvailableCounts = ({ userId }: GetAdmissionsAvailableCountsReqType) =>
  axios
    .get<GetAdmissionsAvailableCountsResType>(`/users/${userId}/payment-histories/total-count`)
    .then((res) => res.data);

export const getUsersUserIdCommunityPosts = ({
  userId,
  ...params
}: GetUsersUserIdCommunityPostsReqType) =>
  axios
    .get<GetUsersUserIdCommunityPostsResType>(`/users/${userId}/community-posts`, { params })
    .then((res) => res.data);

export const getUsersUserIdCommunityComments = ({
  userId,
  ...params
}: GetUsersUserIdCommunityCommentsReqType) =>
  axios
    .get<GetUsersUserIdCommunityCommentsResType>(`/users/${userId}/community-comments`, { params })
    .then((res) => res.data);
