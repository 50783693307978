import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { CheckBox, ComboBox, Input } from '@component/score/common/form';
import { Minus } from '@component/score/common/icon/Minus';
import { Plus } from '@component/score/common/icon/Plus';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import { ErrorMessage } from '@hookform/error-message';
import { APSubject } from '@schema/standardized/ap';
import useMutation from '@simppl/react-query/useMutation';
import { postReportCardSubjects, reportCardDetail, } from '@simppl/repository/test';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
export function APForm({ content }) {
    const methods = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: `${content}.reportCardDetailList`,
    });
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessage, { errors: methods.formState.errors, name: `${content}.reportCardDetailList`, render: ({ message }) => _jsx("p", { className: 'text-[12px] text-red-600', children: message }) }), _jsx("div", { className: 'flex w-full flex-col gap-2', children: fields.map(({ id }, index) => (_jsx(Form, { content: content, index: index, remove: remove }, id))) }), _jsx("div", { className: 'pl-[148px]', children: _jsxs("button", { type: 'button', className: 'flex w-[513px] items-center justify-center gap-[8px] rounded-md border-[1px] border-blue-500 bg-blue-500 px-2 py-1 text-[white]', onClick: () => append({
                        reportCardSubjectId: null,
                        apScore: '0',
                        isAp: false,
                    }), children: [_jsx(Plus, { color: 'white' }), "\uACFC\uBAA9\uCD94\uAC00 (\uC120\uD0DD)"] }) })] }));
}
function Form({ content, index, remove }) {
    const methods = useFormContext();
    const subjectIds = useReportCardSubjectsIds();
    const deleteUserReportCardDetailHook = useMutation(reportCardDetail.delete);
    const postReportCardSubjectsHook = useMutation((name) => postReportCardSubjects(name));
    useEffect(() => {
        const reportCardSubjectId = methods.watch(`${content}.reportCardDetailList.${index}.reportCardSubjectId`) ?? null;
        const reportCardSubjectName = methods.watch(`${content}.reportCardDetailList.${index}.reportCardSubjectName`);
        if (reportCardSubjectName) {
            methods.setValue(`${content}.reportCardDetailList.${index}.name`, reportCardSubjectName);
        }
        const name = methods.watch(`${content}.reportCardDetailList.${index}.name`) ?? null;
        if (reportCardSubjectId === null && name !== null) {
            methods.setValue(`${content}.reportCardDetailList.${index}.reportCardSubjectId`, subjectIds[name] ?? '');
        }
        if (reportCardSubjectId !== null && name === null) {
            methods.setValue(`${content}.reportCardDetailList.${index}.name`, subjectIds[reportCardSubjectId]);
        }
    }, []);
    return (_jsx("div", { className: 'mr-[82px] flex justify-end gap-[24px]', children: _jsx(Controller, { name: `${content}.reportCardDetailList.${index}`, control: methods.control, render: ({ field }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: 'flex justify-end gap-[9px]', children: _jsxs(_Fragment, { children: [_jsx(CheckBox, { type: 'checkbox', content: 'isAp', defaultValue: false, name: `${field.name}.isAp`, title: 'AP' }), _jsx(ComboBox, { type: 'combobox', placeholder: '\uACFC\uBAA9 \uAC80\uC0C9', category: APSubject, forceDisable: methods.getValues(`${field.name}.reportCardSubjectName`), useFlag: false, content: methods.watch(`${field.name}.name`) ?? '', name: `${field.name}.name`, onBlur: () => {
                                        setTimeout(() => {
                                            const name = methods.watch(`${field.name}.name`);
                                            if (name !== '' && !subjectIds[name]) {
                                                postReportCardSubjectsHook.mutate(name, {
                                                    onSuccess: ({ reportCardSubjectId }) => {
                                                        methods.setValue(`${field.name}.reportCardSubjectId`, reportCardSubjectId);
                                                    },
                                                });
                                            }
                                            else if (name !== '' && subjectIds[name]) {
                                                methods.setValue(`${field.name}.reportCardSubjectId`, subjectIds[name]);
                                            }
                                        }, 100);
                                    } }), _jsx(Input, { ...field, type: 'input', inputType: 'stringInput', content: 'apScore', name: `${field.name}.apScore`, defaultValue: 0, size: 'short', alterText: '\uC810\uC218' })] }) }), _jsx("button", { className: ' flex  h-[40px]  w-[40px] items-center justify-center whitespace-nowrap rounded-[4px] border-[1px] border-red-400 text-red-400', type: 'button', onClick: () => {
                            const reportCardDetailId = methods.watch(`${content}.reportCardDetailList.${index}.reportCardDetailId`);
                            if (reportCardDetailId)
                                deleteUserReportCardDetailHook.mutate({ reportCardDetailId }, {
                                    onSuccess: () => remove(index),
                                });
                            else {
                                remove(index);
                            }
                        }, children: _jsx(Minus, {}) })] })) }) }));
}
