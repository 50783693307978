import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import { BackButton } from '@component/score/common';
import { HighSchoolInputForm } from '@component/score/test/highSchool/HighSchoolInputForm';
import { useReportCardSubjectsIds } from '@hook/useReportCardSubjectsIds';
import { reportCardSchema } from '@schema/highSchool';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { reportCardDetail, reportCards } from '@simppl/repository/test';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { HighSchoolModeContext } from './Provider';
export function EditScore() {
    const { id } = useParams();
    const methods = useFormContext();
    const formName = 'editHighSchool';
    const { modeState, changeMode } = useContext(HighSchoolModeContext);
    const subjectIds = useReportCardSubjectsIds();
    const queryClient = useQueryClient();
    const postReportCardHook = useMutation(reportCards.post);
    const postReportCardDetailHook = useMutation(reportCardDetail.post);
    const patchReportCardHook = useMutation(reportCards.patch);
    useEffect(() => {
        if (modeState.data !== undefined && methods.watch(`${formName}.subject`) === undefined) {
            methods.setValue(`${formName}.gpa`, modeState.data.gpa);
            methods.setValue(`${formName}.perfectScore`, modeState.data.perfectScore);
            methods.setValue(`${formName}.grade`, modeState.data.grade);
            methods.setValue(`${formName}.semester`, modeState.data.semester);
            methods.setValue(`${formName}.subject`, modeState.data.curriculum);
            methods.setValue(`${formName}.reportCardDetailList`, modeState.data.reportCardDetailList);
            methods.setValue(`${formName}.reportCardId`, modeState.data.reportCardId);
        }
    }, []);
    const onSave = async () => {
        const data = methods.getValues();
        const { editHighSchool } = data;
        try {
            await reportCardSchema.validateSync(editHighSchool);
            if (modeState.mode === 'new')
                postReportCardHook.mutate({
                    userId: id,
                    highschoolId: modeState.highschoolId,
                    gpa: parseFloat(editHighSchool.gpa),
                    perfectScore: parseFloat(editHighSchool.perfectScore),
                    grade: parseInt(editHighSchool.grade, 10),
                    semester: parseInt(editHighSchool.semester, 10),
                    curriculum: editHighSchool.subject,
                }, {
                    onSuccess: ({ data: { data: { reportCardId }, }, }) => {
                        postReportCardDetailHook.mutate({
                            reportCardId,
                            userId: id,
                            [editHighSchool.subject]: editHighSchool.reportCardDetailList,
                        }, {
                            onSuccess: () => {
                                methods.resetField(formName);
                                queryClient.invalidateQueries(['report-cards-testByUserId']);
                                changeMode('viewTest');
                            },
                            onError: (error) => {
                                alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                                if (error?.response?.data?.message.length > 0) {
                                    alert(error?.response?.data?.message);
                                }
                            },
                        });
                    },
                    onError: (error) => {
                        alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                        if (error?.response?.data?.message.length > 0) {
                            alert(error?.response?.data?.message);
                        }
                    },
                });
            if (modeState.mode === 'edit')
                patchReportCardHook.mutate({
                    userId: id,
                    reportCardId: modeState.data.reportCardId,
                    highschoolId: modeState.highschoolId,
                    gpa: parseFloat(editHighSchool.gpa),
                    perfectScore: parseFloat(editHighSchool.perfectScore),
                    grade: parseInt(editHighSchool.grade, 10),
                    semester: parseInt(editHighSchool.semester, 10),
                    curriculum: editHighSchool.subject,
                }, {
                    onSuccess: async () => {
                        const res = await Promise.all(editHighSchool.reportCardDetailList.map((reportCardDetailListItem) => {
                            if (reportCardDetailListItem.reportCardDetailId === undefined) {
                                reportCardDetailListItem.reportCardSubjectId =
                                    subjectIds[reportCardDetailListItem.name];
                                return reportCardDetail.post({
                                    reportCardId: modeState.data.reportCardId,
                                    userId: id,
                                    [editHighSchool.subject]: [reportCardDetailListItem],
                                });
                            }
                            return reportCardDetail.patch({
                                reportCardDetailId: reportCardDetailListItem.reportCardDetailId,
                                userId: id,
                                [editHighSchool.subject]: [reportCardDetailListItem],
                            });
                        }));
                        if (res.filter((r) => !(r.status === 200 || r.status === 201)).length > 0) {
                            alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                        }
                        else {
                            methods.resetField(formName);
                            queryClient.invalidateQueries(['report-cards-testByUserId']);
                            changeMode('viewTest');
                        }
                    },
                    onError: (error) => {
                        alert('저장중에 에러가 발생했습니다. 성적을 다시한번 확인해주세요.');
                        if (error?.response?.data?.message.length > 0) {
                            // alert(error?.response?.data?.message);
                        }
                    },
                });
        }
        catch (e) {
            alert(e.message);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'border-Blue-Ct-2 relative flex w-full flex-col rounded-[16px] border-[1px] px-[40px] py-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]', children: [_jsx("div", { className: 'absolute left-[42px] top-[32px]', children: _jsx(BackButton, { onClick: () => {
                                changeMode('viewTest');
                                methods.resetField(formName);
                            } }) }), _jsxs("div", { className: 'flex w-full flex-col items-center gap-[48px]', children: [_jsx(Text, { color: 'text-blue-500', weight: 'Bold', as: 'Title', size: 1, children: "\uACE0\uAD50 \uC131\uC801 \uC785\uB825" }), _jsx(HighSchoolInputForm, { name: formName, removeSemester: () => {
                                    methods.resetField(formName);
                                    changeMode('viewTest');
                                } })] })] }), _jsx(Spacing, { height: 80 }), _jsx("button", { type: 'button', className: 'h-[40px] w-full rounded-md border-[1px] border-blue-500 bg-blue-500 text-[white]', onClick: () => {
                    onSave();
                }, children: "\uC800\uC7A5" })] }));
}
