import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/rules-of-hooks */
import { UniversityMajorModal } from '@component/database/university/UniversityMajorModal';
import { UniversityModal } from '@component/database/university/UniversityModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useUniversities } from '@hook/useUniversities';
import { useUniversitiesMajors } from '@hook/useUniversitiesMajors';
import { Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Image, Input, Link, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, } from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { universities, universitiesMajors } from '@simppl/repository/universities';
import { Search } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
export default function UniversityPage() {
    const { universityId } = useParams();
    const isUniversityPage = !universityId;
    const isSubjectPage = !!universityId;
    return (_jsxs("div", { className: 'flex min-h-[80%] flex-col', children: [isUniversityPage && _jsx(UniversityTable, {}), isSubjectPage && _jsx(SubjectTable, {})] }));
}
function UniversityTable() {
    const { data } = useUniversities({
        // isMain null 전체 대학 조회
        name: '',
        page: 1,
        size: 1000000,
    });
    if (!data)
        return null;
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (university, value) => university.name.includes(value));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteUniversitiesHook = useMutation(universities.delete);
    const queryClient = useQueryClient();
    return (_jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(UniversityModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                    onClose();
                    setModalState(null);
                } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uB300\uD559\uAD50 \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                        setModalState({ mode: 'add' });
                                        onOpen();
                                    }, children: "\uB300\uD559 \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uB300\uD559 \uC774\uB984" }, 'name'), _jsx(TableColumn, { children: "\uB300\uD559 \uC774\uBBF8\uC9C0" }, 'image'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (university) => (_jsxs(TableRow, { className: 'relative cursor-pointer', as: Link, href: `/database/university/${university.universityId}`, children: [_jsx(TableCell, { children: university.name }), _jsx(TableCell, { children: _jsx(Image, { src: university.image, alt: university.name, width: 50, height: 50 }) }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                setModalState({ mode: 'edit', universityId: university.universityId });
                                                                onOpen();
                                                            }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                deleteUniversitiesHook.mutate({ universityId: university.universityId }, {
                                                                    onSuccess: () => {
                                                                        queryClient.invalidateQueries(['universities']);
                                                                    },
                                                                });
                                                            }, children: "\uC0AD\uC81C" })] })] }) })] }, university.universityId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }));
}
function SubjectTable() {
    const { universityId } = useParams();
    if (!universityId)
        return null;
    const { data } = useUniversitiesMajors({
        universityId,
    });
    if (!data)
        return null;
    const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } = useSearchAndPagination(data, (university, value) => university.name.includes(value));
    const [modalState, setModalState] = useState(null);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
    const deleteUniversitiesHook = useMutation(universitiesMajors.delete);
    const queryClient = useQueryClient();
    return (_jsxs(Card, { className: 'grow', children: [isOpen && modalState !== null && (_jsx(UniversityMajorModal, { isOpen: isOpen, onOpenChange: onOpenChange, ...modalState, onClose: () => {
                    onClose();
                    setModalState(null);
                } })), _jsx(CardHeader, { children: _jsx("h1", { className: 'text-2xl', children: "\uD559\uACFC \uAD00\uB9AC" }) }), _jsx(Divider, {}), _jsx(CardBody, { className: 'flex flex-col gap-4', children: _jsxs(Table, { layout: 'auto', topContent: _jsx("div", { className: 'flex flex-col gap-4', children: _jsxs("div", { className: 'flex w-full flex-row justify-between', children: [_jsx("div", { className: 'flex max-w-sm flex-1 flex-row items-center', children: _jsx(Input, { labelPlacement: 'outside', startContent: _jsx(Search, {}), value: filterValue, onClear: () => onClear(), onValueChange: onSearchChange }) }), _jsx(Button, { color: 'primary', onPress: () => {
                                        setModalState({ mode: 'add' });
                                        onOpen();
                                    }, children: "\uD559\uACFC \uCD94\uAC00" })] }) }), topContentPlacement: 'outside', children: [_jsxs(TableHeader, { children: [_jsx(TableColumn, { children: "\uD559\uACFC \uC774\uB984" }, 'name'), _jsx(TableColumn, { children: "\uBE44\uACE0" }, 'setting')] }), _jsx(TableBody, { items: items, children: (major) => (_jsxs(TableRow, { className: 'relative cursor-pointer', children: [_jsx(TableCell, { children: major.name }), _jsx(TableCell, { children: _jsxs(Dropdown, { children: [_jsx(DropdownTrigger, { children: _jsx(Button, { isIconOnly: true, size: 'sm', variant: 'light', children: _jsx(VerticalDotsIcon, { className: 'text-default-300' }) }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { onPress: () => {
                                                                setModalState({
                                                                    mode: 'edit',
                                                                    universityMajorId: major.universityMajorId,
                                                                });
                                                                onOpen();
                                                            }, children: "\uC218\uC815" }), _jsx(DropdownItem, { className: 'text-red-500', onPress: () => {
                                                                if (!confirm('정말 삭제하시겠습니까?'))
                                                                    return;
                                                                deleteUniversitiesHook.mutate({
                                                                    universityId,
                                                                    universityMajorId: major.universityMajorId,
                                                                }, {
                                                                    onSuccess: () => {
                                                                        queryClient.invalidateQueries(['unversitiesMajors']);
                                                                    },
                                                                });
                                                            }, children: "\uC0AD\uC81C" })] })] }) })] }, major.universityMajorId)) })] }) }), _jsx(CardFooter, { children: _jsx("div", { className: 'flex w-full flex-col items-center', children: _jsx(Pagination, { isCompact: true, showControls: true, showShadow: true, page: page, onChange: setPage, total: Math.max(Math.ceil(filteredItems.length / 10), 1) }) }) })] }));
}
