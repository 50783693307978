import axios from '..';
import type {
  DeleteUniversitiesMajorsReqType,
  DeleteUniversitiesReqType,
  GetAdmissionsInformationReqType,
  GetUniversitiesMajorsReqType,
  GetUniversitiesReqType,
  PatchUniversitiesMajorsReqType,
  PatchUniversitiesReqType,
  PostMainUniversitiesReqType,
  PostUniversitiesMajorsReqType,
  PostUniversitiesReqType,
} from './req';
import type {
  DeleteUniversitiesMajorsResType,
  DeleteUniversitiesResType,
  GetAdmissionsInformationResType,
  GetUniversitiesMajorsResType,
  GetUniversitiesResType,
  PatchUniversitiesResType,
  PostMainUniversitiesResType,
  PostUniversitiesMajorsResType,
  PostUniversitiesResType,
} from './res';

export const mainUniversities = {
  post: (body: PostMainUniversitiesReqType) =>
    axios
      .post<PostMainUniversitiesResType>('/universities', { ...body, isMain: true })
      .then((res) => res.data),
};

export const universities = {
  get: (params: GetUniversitiesReqType) =>
    axios.get<GetUniversitiesResType>('/universities', { params }).then((res) => res.data),
  post: (body: PostUniversitiesReqType) =>
    axios.post<PostUniversitiesResType>('/universities', { ...body }).then((res) => res.data),
  delete: ({ universityId }: DeleteUniversitiesReqType) =>
    axios
      .delete<DeleteUniversitiesResType>(`/universities/${universityId}`)
      .then((res) => res.data),
  patch: ({ universityId, ...body }: PatchUniversitiesReqType) =>
    axios
      .patch<PatchUniversitiesResType>(`/universities/${universityId}`, body)
      .then((res) => res.data),
};

export const universitiesAdmissions = {
  get: ({ universityId }: GetAdmissionsInformationReqType) =>
    axios
      .get<GetAdmissionsInformationResType>(`/universities/${universityId}/admissions`)
      .then((res) => res.data),
};

export const universitiesMajors = {
  get: ({ universityId }: GetUniversitiesMajorsReqType) =>
    axios
      .get<GetUniversitiesMajorsResType>(`/universities/${universityId}/majors`)
      .then((res) => res.data),

  post: ({ universityId, ...body }: PostUniversitiesMajorsReqType) =>
    axios
      .post<PostUniversitiesMajorsResType>(`/universities/${universityId}/majors`, body)
      .then((res) => res.data),
  delete: ({ universityId, universityMajorId }: DeleteUniversitiesMajorsReqType) =>
    axios
      .delete<DeleteUniversitiesMajorsResType>(
        `/universities/${universityId}/majors/${universityMajorId}`,
      )
      .then((res) => res.data),

  patch: ({ universityId, universityMajorId, ...body }: PatchUniversitiesMajorsReqType) =>
    axios
      .patch<PatchUniversitiesResType>(
        `/universities/${universityId}/majors/${universityMajorId}`,
        body,
      )
      .then((res) => res.data),
};
